var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"tZMTZcwX88p9nAGnyRRhk"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/**
 * This file configures the initialization of Sentry on the browser.
 * The config you add here will be used whenever a page is visited.
 *
 * @see {@link https://docs.sentry.io/platforms/javascript/guides/nextjs/}
 */
import * as Sentry from '@sentry/nextjs';

if (process.env.NEXT_PUBLIC_HAS_SENTRY_DISABLED !== 'true') {
  Sentry.init({
    // Adjust this value in production, or use tracesSampler for greater control
    dsn: 'https://5d0531446338493f9062c1344d46b795@o293515.ingest.sentry.io/6350344',
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,

    /**
     * Note: if you want to override the automatic release value, do not set a
     * `release` value here - use the environment variable `SENTRY_RELEASE`, so
     * that it will also get attached to your source maps
     */
  });
}
