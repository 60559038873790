import cx from 'classnames';
import { useContext, useEffect } from 'react';

import GlobalNav from './GlobalNav';

import ViewportContext from 'components/ViewportContext';

import useNavbarPadding from 'hooks/useNavbarPadding';
import useScrollLock from 'hooks/useScrollLock';
import useStatus from 'hooks/useStatus';

type PageWrapperProps = {
  children: React.ReactNode;
  className?: string;
  pageId?: string;
  withoutGlobalNav?: boolean;
};

const PageWrapper = (props: PageWrapperProps) => {
  const { isMainContentScrollable } = useContext(ViewportContext);
  const { user } = useStatus();
  const padding = useNavbarPadding();

  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    if (!isMainContentScrollable) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [isMainContentScrollable]);

  return (
    <>
      {/* This div shows a cyan border around the whole side when isSupportSession is true */}
      <div
        className={cx('', {
          'border-solid border-cyan-50 border-4 w-full h-screen fixed !z-[101] pointer-events-none':
            user && user.isSupportSession,
        })}
      />
      {user && !props.withoutGlobalNav && <GlobalNav pageId={props.pageId} />}
      <div
        className={cx('transition-spacing duration-300 w-full', props.className, {
          [padding]: Boolean(user && padding && !props.withoutGlobalNav),
        })}
      >
        {props.children}
      </div>
    </>
  );
};

export default PageWrapper;
